<template>
  <div class="facial-features q-w360 q-fit">
    <face-features
      class="facial-features__image"
      :gender="gender"
      :eyes="eyeColorImageValue"
      :hair="naturalHairColorImageValue"
    />

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['eyeColor'])">
      <single-answer-question
        title="label.myEyeColor"
        :value="eyeColor"
        :options="$options.eyeColorOptions"
        two-columns
        @input="onFieldChange('eyeColor', $event)"
        @option-mouseover="onOptionMouseOver('eyeColor', $event)"
        @option-mouseleave="onOptionMouseLeave"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['naturalHairColor'])"
    >
      <single-answer-question
        id="hair-color"
        title="label.myHairColor"
        :value="naturalHairColor"
        :options="$options.hairColorOptions"
        @input="onFieldChange('naturalHairColor', $event)"
        @option-mouseover="onOptionMouseOver('naturalHairColor', $event)"
        @option-mouseleave="onOptionMouseLeave"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import FaceFeatures from '@/modules/questionnaire/new-design-components/FaceFeatures';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { EYE_COLOR, HAIR_COLOR } from '@/modules/questionnaire/api/constants';

const EYE_COLOR_OPTIONS = [
  { value: EYE_COLOR.BLUE, text: 'eyeColor.blue' },
  { value: EYE_COLOR.GREY, text: 'eyeColor.grey' },
  { value: EYE_COLOR.GREEN, text: 'eyeColor.green' },
  { value: EYE_COLOR.HAZEL, text: 'eyeColor.hazel' },
  { value: EYE_COLOR.BROWN, text: 'eyeColor.brown' },
  { value: EYE_COLOR.BLACK, text: 'eyeColor.black' }
];

const HAIR_COLOR_OPTIONS = [
  { value: HAIR_COLOR.RED, text: 'hairColor.red' },
  { value: HAIR_COLOR.LIGHT_BLONDE, text: 'hairColor.lightBlonde' },
  { value: HAIR_COLOR.MEDIUM_BLONDE, text: 'hairColor.mediumBlonde' },
  { value: HAIR_COLOR.DARK_BLONDE, text: 'hairColor.darkBlonde' },
  { value: HAIR_COLOR.LIGHT_BROWN, text: 'hairColor.lightBrown' },
  { value: HAIR_COLOR.MEDIUM_BROWN, text: 'hairColor.mediumBrown' },
  { value: HAIR_COLOR.DARK_BROWN, text: 'hairColor.darkBrown' },
  { value: HAIR_COLOR.BLACK, text: 'hairColor.black' }
];

export default {
  name: 'FacialFeaturesTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion, FaceFeatures },
  mixins: [stepTemplateMixin],
  eyeColorOptions: EYE_COLOR_OPTIONS,
  hairColorOptions: HAIR_COLOR_OPTIONS,
  props: {
    gender: {
      type: String,
      required: true
    },
    eyeColor: {
      type: String,
      default: ''
    },
    naturalHairColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mouseOverField: null
    };
  },
  computed: {
    eyeColorImageValue() {
      const { fieldName, fieldValue } = this.mouseOverField || {};

      return fieldName === 'eyeColor' ? fieldValue : this.eyeColor;
    },
    naturalHairColorImageValue() {
      const { fieldName, fieldValue } = this.mouseOverField || {};

      return fieldName === 'naturalHairColor' ? fieldValue : this.naturalHairColor;
    }
  },
  methods: {
    onOptionMouseOver(fieldName, fieldValue) {
      this.mouseOverField = { fieldName, fieldValue };
    },
    onOptionMouseLeave() {
      this.mouseOverField = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.facial-features {
  &__image {
    width: 80px;
    height: 143px;
    margin: 0 auto;
  }
}

@media (min-width: $desktop-start) {
  .facial-features {
    &__image {
      width: 85px;
      height: 154px;
    }
  }
}
</style>
